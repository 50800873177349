// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-template-index-tsx": () => import("./../../../src/templates/ArticleTemplate/index.tsx" /* webpackChunkName: "component---src-templates-article-template-index-tsx" */),
  "component---src-templates-blog-template-index-tsx": () => import("./../../../src/templates/BlogTemplate/index.tsx" /* webpackChunkName: "component---src-templates-blog-template-index-tsx" */),
  "component---src-templates-categories-template-index-tsx": () => import("./../../../src/templates/CategoriesTemplate/index.tsx" /* webpackChunkName: "component---src-templates-categories-template-index-tsx" */),
  "component---src-templates-exam-template-index-tsx": () => import("./../../../src/templates/ExamTemplate/index.tsx" /* webpackChunkName: "component---src-templates-exam-template-index-tsx" */),
  "component---src-templates-home-template-index-tsx": () => import("./../../../src/templates/HomeTemplate/index.tsx" /* webpackChunkName: "component---src-templates-home-template-index-tsx" */),
  "component---src-templates-slides-template-index-tsx": () => import("./../../../src/templates/SlidesTemplate/index.tsx" /* webpackChunkName: "component---src-templates-slides-template-index-tsx" */),
  "component---src-templates-tags-template-index-tsx": () => import("./../../../src/templates/TagsTemplate/index.tsx" /* webpackChunkName: "component---src-templates-tags-template-index-tsx" */)
}

