import React, { FC, createContext, useReducer, Dispatch } from 'react';
import componentConfig from '../../config/component.yml';
import {
    ILandingPageTemplateData,
    IArticleTemplateData,
    ICategoriesTemplateData,
    ITagsTemplateData,
    ISocialMediaPath,
    IHeaderComponent,
    IAnnouncementData,
    IFacebookPlugin,
    IRecruitment,
    IOnlineExam,
} from './provider';

type State = {
    isLoading: boolean;
    landingPageTemplateData: ILandingPageTemplateData;
    articleTemplateData: IArticleTemplateData;
    categoriesTemplateData: ICategoriesTemplateData;
    tagsTemplateData: ITagsTemplateData;
    socialMediaPath: ISocialMediaPath;
    headerComponent: IHeaderComponent;
    announcementData: IAnnouncementData;
    facebookPlugin: IFacebookPlugin;
    recruitment: IRecruitment;
    onlineExam: IOnlineExam;
};

export const initialState: State = {
    isLoading: false,
    landingPageTemplateData: componentConfig.landingPage,
    articleTemplateData: componentConfig.articlePage,
    categoriesTemplateData: componentConfig.categoriesPage,
    tagsTemplateData: componentConfig.tagsPage,
    socialMediaPath: componentConfig.socialMediaPath,
    headerComponent: componentConfig.header,
    announcementData: componentConfig.announcement,
    facebookPlugin: componentConfig.facebookPlugin,
    recruitment: componentConfig.recruitment,
    onlineExam: componentConfig.onlineExam,
};

type Action = { type: 'SET_LOADING'; payload: boolean } | { type: 'SET_ANNOUNCEMENT'; payload: boolean };

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload,
            };
        case 'SET_ANNOUNCEMENT':
            return {
                ...state,
                announcementData: {
                    ...state.announcementData,
                    enabled: action.payload,
                },
            };
        default:
            throw new Error('Global reducer error');
    }
};

export const GlobalContext = createContext<{
    state: State;
    dispatch: Dispatch<Action>;
}>({
    state: initialState,
    dispatch: () => null,
});

export const GlobalProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};
