module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-40421750-25","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N7RD33K","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"91APP Technical Network","short_name":"91APP Blog","start_url":"/","description":"91APP Technical Network","background_color":"#fff","theme_color":"#f72845","icon":"/Users/alfredotang/Desktop/91app-tech-blog/content/images/main/91APPTechBlogLogo.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3b449c9bcdf5e8f5b73ccf0c3ab10403"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"wrapperStyle":"margin-top: 20px; margin-bottom: 20px"}},"gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-vscode","options":{"theme":"Dark+ (default dark)"}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/Users/alfredotang/Desktop/91app-tech-blog","configDir":".gatsby"},
    }]
